<template>
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			opacity="0.4"
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M21.2243 3.83998H10.7894C5.57199 3.83998 4.27454 5.20765 4.27454 10.6648V25.9122C4.27454 29.5142 6.28973 30.3673 8.73282 27.7944L8.74662 27.7809C9.87844 26.6028 11.6038 26.6976 12.5838 27.984L13.9779 29.8121C15.0959 31.261 16.904 31.261 18.0221 29.8121L19.4161 27.984C20.4099 26.6841 22.1353 26.5893 23.2671 27.7809C25.724 30.3537 27.7254 29.5006 27.7254 25.8987V10.6648C27.7392 5.20765 26.4417 3.83998 21.2243 3.83998ZM10.4858 11.6261H21.528C22.0939 11.6261 22.5632 11.1657 22.5632 10.6105C22.5632 10.0553 22.0939 9.59489 21.528 9.59489H10.4858C9.91988 9.59489 9.45059 10.0553 9.45059 10.6105C9.45059 11.1657 9.91988 11.6261 10.4858 11.6261ZM11.8661 17.0426H20.1477C20.7136 17.0426 21.1829 16.5822 21.1829 16.027C21.1829 15.4718 20.7136 15.0114 20.1477 15.0114H11.8661C11.3002 15.0114 10.8309 15.4718 10.8309 16.027C10.8309 16.5822 11.3002 17.0426 11.8661 17.0426Z"
			fill="white"
		/>
		<path
			d="M21.5275 11.6258H10.4853C9.91937 11.6258 9.45007 11.1654 9.45007 10.6102C9.45007 10.0551 9.91937 9.59465 10.4853 9.59465H21.5275C22.0934 9.59465 22.5627 10.0551 22.5627 10.6102C22.5627 11.1654 22.0934 11.6258 21.5275 11.6258Z"
			fill="white"
		/>
		<path
			d="M20.1472 17.0423H11.8656C11.2996 17.0423 10.8303 16.5819 10.8303 16.0267C10.8303 15.4715 11.2996 15.0111 11.8656 15.0111H20.1472C20.7131 15.0111 21.1824 15.4715 21.1824 16.0267C21.1824 16.5819 20.7131 17.0423 20.1472 17.0423Z"
			fill="white"
		/>
	</svg>
</template>
