<script setup>
	import IconCheck from "./components/icons/IconCheck.vue";
	import AppNavigation from "./components/AppNavigation.vue";
	import NavigationButton from "./components/NavigationButton.vue";
	import IconClock from "./components/icons/IconClock.vue";
	import IconTicket from "./components/icons/IconTicket.vue";
	import IconPrize from "./components/icons/IconPrize.vue";
	import IconQuestions from "./components/icons/IconQuestions.vue";
	import PreloaderScreen from "./components/PreloaderScreen.vue";
	import { onMounted, ref } from "vue";

	const isLoading = ref(true);
	onMounted(() => {
		loadData();
	});

	const loadData = () => {
		// TODO: API запросы

		setTimeout(() => {
			isLoading.value = false;
		}, 5500);
	};
</script>

<template>
	<PreloaderScreen :is-loading="isLoading" />
	<div v-if="!isLoading" class="main">
		<router-view />
		<AppNavigation>
			<NavigationButton to="/check">
				<IconCheck />
			</NavigationButton>
			<NavigationButton to="/history">
				<IconClock />
			</NavigationButton>
			<NavigationButton to="/" reverse-color>
				<IconTicket />
			</NavigationButton>
			<NavigationButton to="/prizes">
				<IconPrize />
			</NavigationButton>
			<NavigationButton to="/faq" reverse-color>
				<IconQuestions />
			</NavigationButton>
		</AppNavigation>
	</div>
</template>

<style scoped lang="scss">
	.main {
		width: 100vw;
		height: 100vh;
		background: url("@/assets/img/background-pattern.svg");
		overflow: auto;
		padding-bottom: 74px;
	}
</style>
