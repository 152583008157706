<template>
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1.7699 16C1.7699 4.28146 4.28152 1.76984 16 1.76984C27.7186 1.76984 30.2302 4.28146 30.2302 16C30.2302 27.7185 27.7186 30.2301 16 30.2301C4.28152 30.2301 1.7699 27.7185 1.7699 16Z"
			fill="#561750"
		/>
		<path
			d="M21.0774 14.0312H10.0352C9.46929 14.0312 9 13.5708 9 13.0156C9 12.4604 9.46929 12 10.0352 12H21.0774C21.6433 12 22.1126 12.4604 22.1126 13.0156C22.1126 13.5708 21.6433 14.0312 21.0774 14.0312Z"
			fill="white"
		/>
		<path
			d="M19.6971 19.4477H11.4155C10.8496 19.4477 10.3803 18.9873 10.3803 18.4321C10.3803 17.8769 10.8496 17.4165 11.4155 17.4165H19.6971C20.263 17.4165 20.7323 17.8769 20.7323 18.4321C20.7323 18.9873 20.263 19.4477 19.6971 19.4477Z"
			fill="white"
		/>
	</svg>
</template>
