<script setup>
	import { onMounted, ref, watch } from "vue";
	const line = ref(null);
	const finger = ref(null);
	const duration = 4000;
	const pathLength = ref(0);
	const animationFrame = ref(null);
	const props = defineProps({
		isIdle: {
			type: Boolean,
		},
	});

	onMounted(() => {
		pathLength.value = line.value.getTotalLength();
		animationFrame.value = requestAnimationFrame(animateRect);
	});

	function animateRect(timestamp) {
		const elapsedTime = timestamp % duration;
		const progress = elapsedTime / duration;
		const pointAtLength = line?.value?.getPointAtLength(
			progress * pathLength.value
		);

		finger.value.setAttribute(
			"transform",
			`translate(${pointAtLength.x}, ${pointAtLength.y})`
		);

		animationFrame.value = requestAnimationFrame(animateRect);
	}

	watch(props, () => {
		if (props.isIdle && pathLength.value > 0) {
			animationFrame.value = requestAnimationFrame(animateRect);
		} else {
			cancelAnimationFrame(animationFrame.value);
		}
	});
</script>

<template>
	<div class="scratch-card-idle" :class="{ 'scratch-card-idle--idle': isIdle }">
		<svg
			class="scratch-card-idle__line"
			width="253"
			height="230"
			viewBox="0 0 253 230"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				ref="line"
				d="M12.5 48.5C60 13.5 170.5 -3.5 132.933 32.186C92.5822 70.5174 -3 170 68 138C165.826 93.9092 269.5 100 231.437 149.408C195.344 196.257 210.397 223.878 241 216.523"
				stroke="#765B3F"
				stroke-width="24"
				stroke-linecap="round"
			/>
		</svg>
		<svg
			ref="finger"
			class="scratch-card-idle__finger"
			width="148"
			height="175"
			viewBox="0 0 148 175"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="30" cy="30" r="30" fill="white" />
			<path
				d="M72.7623 46.8176C73.3736 45.3914 74.3923 45.3914 75.6148 45.7989C82.5423 49.8738 83.561 55.1713 79.0785 61.895C74.3923 68.6186 74.7998 73.7124 81.116 80.2323C101.491 100.811 118.402 124.038 135.109 147.673C137.758 151.544 143.667 155.619 137.962 161.528C118.606 161.732 100.065 168.251 80.7085 168.048C74.7998 166.01 72.3548 160.305 68.6874 156.027C57.685 143.394 47.9051 129.947 37.5139 117.314C31.6052 110.183 27.3265 102.237 24.2703 93.4759C19.7878 80.8435 22.6403 69.4336 34.0502 62.9137C40.7739 59.0425 39.9589 56.19 36.0877 51.5038C33.0315 47.6326 31.809 43.1501 30.994 38.4639C30.5865 35.204 30.5865 31.944 33.6427 30.314C36.4952 28.684 38.7364 31.5365 40.7739 32.9627C49.7388 39.6864 54.0175 49.8738 60.13 59.6537C64.2049 54.9675 67.6686 49.8738 72.7623 46.8176Z"
				fill="#561750"
			/>
			<path
				d="M98.8421 104.275C113.104 122.205 128.386 139.524 139.592 159.695C142.852 162.955 145.704 164.177 147.538 158.88C147.742 152.156 142.24 148.285 139.184 143.191C136.535 140.135 134.702 136.671 132.664 133.411C127.774 126.687 122.884 120.371 117.994 113.851C115.549 110.591 108.743 104.086 108.743 104.086C108.743 104.086 103.936 99.5889 100.472 95.9215C94.9709 89.809 89.6734 83.2891 83.561 77.5841C77.856 72.0829 77.4485 67.8042 82.1347 61.2843C88.2472 52.7269 86.2097 49.0594 75.8185 45.7994C83.561 52.3194 79.2823 59.2468 75.411 65.3593C72.7623 69.4342 70.1136 73.9167 74.1886 76.9729C84.376 84.5116 91.3034 94.699 98.8421 104.275Z"
				fill="black"
			/>
			<path
				d="M80.7066 174.161C78.6691 174.161 76.8354 174.161 74.7979 174.161C72.3529 172.938 69.7042 172.531 68.0742 170.086C63.5918 158.065 53.6081 149.711 46.6807 139.523C37.1045 125.872 25.2871 114.055 17.5447 98.9776C9.39478 83.6965 16.3222 69.8416 27.9359 61.488C32.6221 58.228 32.4183 56.3943 29.7696 52.1156C27.3246 48.2443 25.6946 43.7619 24.2684 39.4832C23.0459 35.612 22.8422 31.3333 26.9171 28.4808C31.1958 25.4246 35.067 27.6658 38.1233 30.1108C43.6245 34.5932 47.6994 40.0944 50.9594 46.4106C53.2006 50.8931 56.8681 58.8392 62.9805 48.6518C63.7955 47.4293 66.648 47.2256 68.4817 46.6144C64.203 52.3193 59.1093 57.413 55.8493 65.5629C50.5519 53.338 45.0507 42.9469 36.4933 34.5932C34.6595 32.7595 32.2146 30.3145 30.1771 31.3332C27.5284 32.7595 28.7508 36.2232 29.3621 38.8719C30.7883 47.4293 36.697 53.7455 40.9757 60.8767C20.1934 65.3592 14.4885 83.4928 27.7321 103.868C41.9945 125.669 59.9243 144.617 75.2054 165.603C76.6316 168.252 80.2991 167.437 81.7254 170.086C81.9291 171.716 81.9291 172.938 80.7066 174.161Z"
				fill="black"
			/>
			<path
				d="M27.9361 86.1403C46.6809 90.8265 47.6997 76.7679 53.4046 66.3767C57.4796 77.7866 51.3671 84.3065 45.0509 89.8077C40.7722 93.2715 34.8635 94.6977 27.9361 86.1403Z"
				fill="black"
			/>
		</svg>
	</div>
</template>

<style scoped lang="scss">
	.scratch-card-idle {
		position: absolute;
		width: 340px;
		height: 286px;
		top: 0;
		left: 0;
		pointer-events: none;
		z-index: 3;
		opacity: 0;
		transition: 0.3s ease;
		&__line {
			position: absolute;
			top: 22px;
			left: 30px;
		}
		&__finger {
			position: absolute;
			z-index: 1;
		}
		&--idle {
			opacity: 1;
		}
	}
</style>
