<template>
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			opacity="0.4"
			d="M16 29.9691C23.7149 29.9691 29.9691 23.7149 29.9691 16C29.9691 8.28509 23.7149 2.03091 16 2.03091C8.28506 2.03091 2.03088 8.28509 2.03088 16C2.03088 23.7149 8.28506 29.9691 16 29.9691Z"
			fill="white"
		/>
		<path
			d="M21.1827 21.49C21.0011 21.49 20.8195 21.4481 20.6519 21.3363L16.3214 18.752C15.2458 18.1095 14.4496 16.6986 14.4496 15.4553V9.72801C14.4496 9.15528 14.9245 8.68033 15.4973 8.68033C16.07 8.68033 16.5449 9.15528 16.5449 9.72801V15.4553C16.5449 15.9582 16.964 16.6986 17.3971 16.95L21.7275 19.5343C22.2304 19.8277 22.384 20.4702 22.0907 20.9731C21.8811 21.3084 21.5319 21.49 21.1827 21.49Z"
			fill="white"
		/>
	</svg>
</template>
