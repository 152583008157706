import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import PrizesView from "../views/PrizesView.vue";
import CheckView from "../views/CheckView.vue";
import HistoryView from "../views/HistoryView.vue";
import FAQView from "@/views/FAQView.vue";

const routes = [
	{
		path: "/",
		name: "home",
		component: HomeView,
	},
	{
		path: "/prizes",
		name: "prizes",
		component: PrizesView,
	},
	{
		path: "/check",
		name: "check",
		component: CheckView,
	},
	{
		path: "/history",
		name: "history",
		component: HistoryView,
	},
	{
		path: "/faq",
		name: "faq",
		component: FAQView,
	},
];
const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
