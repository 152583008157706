<template>
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.6565 21.3399C11.3119 21.3399 10.2521 20.1627 10.3187 18.7431L10.3529 18.0161C10.5414 13.9998 13.4214 12.0262 14.5066 11.2645C15.7606 10.3989 16.1818 9.8103 16.224 8.91009C16.3052 7.17891 15.0274 5.75922 13.3877 5.75922C11.7479 5.75922 10.3368 7.17891 10.2556 8.91009C10.189 10.3297 9.01871 11.5069 7.67414 11.5069C6.32957 11.5069 5.2698 10.3297 5.33642 8.91009C5.55253 4.30515 9.26974 0.565674 13.6314 0.565674C17.9931 0.565674 21.3593 4.30515 21.1432 8.91009C20.9579 12.8572 18.1107 14.8307 17.0599 15.5578C15.7387 16.458 15.3175 17.0467 15.272 18.0161L15.2379 18.7431C15.1697 20.1973 14.001 21.3399 12.6565 21.3399Z"
			fill="white"
			opacity="0.4"
		/>
		<path
			d="M12.2524 29.962C10.8751 29.962 9.84809 28.7848 9.91471 27.3652C9.98133 25.9456 11.1516 24.7684 12.4962 24.7684C13.8407 24.7684 14.9005 25.9456 14.8339 27.3652C14.7673 28.7848 13.6298 29.962 12.2524 29.962Z"
			fill="white"
			opacity="0.4"
		/>
		<path
			d="M16.5232 23.578C15.5702 23.265 15.0932 22.184 15.4708 21.1934L15.6642 20.6861C16.7328 17.8834 19.2334 17.1551 20.1798 16.8678C21.2701 16.5463 21.7056 16.2272 21.9451 15.599C22.4057 14.3909 21.8305 13.0873 20.6683 12.7056C19.5062 12.3239 18.1756 13.0016 17.715 14.2096C17.3374 15.2002 16.2339 15.7621 15.281 15.4491C14.328 15.1362 13.851 14.0551 14.2286 13.0645C15.4537 9.85113 18.9588 8.06612 22.05 9.08144C25.1413 10.0968 26.6566 13.5307 25.4315 16.7441C24.3814 19.4984 21.904 20.2343 20.9901 20.505C19.8441 20.8355 19.4086 21.1547 19.1506 21.8312L18.9572 22.3385C18.5704 23.3532 17.4761 23.891 16.5232 23.578Z"
			fill="white"
		/>
		<path
			d="M14.2297 29.5948C13.2535 29.2741 12.7997 28.2007 13.1774 27.2101C13.5551 26.2195 14.6585 25.6576 15.6115 25.9706C16.5644 26.2836 17.0415 27.3646 16.6638 28.3552C16.2861 29.3458 15.2059 29.9154 14.2297 29.5948Z"
			fill="white"
		/>
	</svg>
</template>
