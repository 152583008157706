<script setup>
	import { onMounted, ref, watch } from "vue";
	import IconStickerPack from "./icons/IconStickerPack.vue";
	import ScratchCardReward from "./ScratchCardReward.vue";
	import ScratchCardIdle from "./ScratchCardIdle.vue";

	const emits = defineEmits(["wasteAttempt"]);
	defineProps({
		reward: {
			type: String,
			default: "Стикерпак",
		},
	});

	const canvas = ref(null);
	const ctx = ref(null);
	const radius = 30;
	const isDrawing = ref(false);
	const imageIsLoaded = ref(false);
	const clearedPercentage = ref(0);
	const imageSrc = new URL("../assets/img/scratch-layer.svg", import.meta.url)
		.href;
	const isOpened = ref(false);
	const originalImageData = ref(null);
	const isIdle = ref(true);
	const timeout = ref(null);
	onMounted(() => {
		ctx.value = canvas.value.getContext("2d");
		const image = new Image();
		image.src = imageSrc;

		image.onload = () => {
			ctx.value.drawImage(image, 0, 0);
			canvas.value.style.background = "none";
			imageIsLoaded.value = true;

			originalImageData.value = ctx.value.getImageData(
				0,
				0,
				canvas.value.width,
				canvas.value.height
			);
		};
	});

	const erase = (x, y) => {
		if (!isDrawing.value || !imageIsLoaded.value) return;
		ctx.value.globalCompositeOperation = "destination-out";
		ctx.value.beginPath();
		ctx.value.arc(x, y, radius, 0, Math.PI * 2, false);
		ctx.value.fill();
		calculateClearedArea();
	};

	const startErasing = (e) => {
		isDrawing.value = true;
		const rect = canvas.value.getBoundingClientRect();
		const x = (e.touches ? e.touches[0].clientX : e.clientX) - rect.left;
		const y = (e.touches ? e.touches[0].clientY : e.clientY) - rect.top;
		isIdle.value = false;
		clearTimeout(timeout.value);
		erase(x, y);
	};

	const continueErasing = (e) => {
		if (!isDrawing.value) return;
		const rect = canvas.value.getBoundingClientRect();
		const x = (e.touches ? e.touches[0].clientX : e.clientX) - rect.left;
		const y = (e.touches ? e.touches[0].clientY : e.clientY) - rect.top;
		erase(x, y);
	};

	const stopErasing = () => {
		isDrawing.value = false;
		clearTimeout(timeout.value);
		timeout.value = setTimeout(() => {
			isIdle.value = true;
		}, 5000);
	};

	const calculateClearedArea = () => {
		const ctx = canvas.value.getContext("2d");
		const imageData = ctx.getImageData(
			0,
			0,
			canvas.value.width,
			canvas.value.height
		);
		const pixels = imageData.data;
		const totalPixels = pixels.length / 4;
		let clearedPixels = 0;

		for (let i = 3; i < pixels.length; i += 4) {
			if (pixels[i] === 0) {
				clearedPixels++;
			}
		}

		const cleared = (clearedPixels / totalPixels) * 100;
		clearedPercentage.value = cleared.toFixed(2);
	};

	const clearCanvas = () => {
		stopErasing();
		ctx.value.clearRect(0, 0, canvas.value.width, canvas.value.height);
		isOpened.value = true;
		clearedPercentage.value = 0;
		imageIsLoaded.value = false;
	};

	watch(clearedPercentage, () => {
		if (clearedPercentage.value >= 60) {
			clearCanvas();
		}
	});

	const handleClose = () => {
		isOpened.value = false;
		emits("wasteAttempt");

		if (originalImageData.value) {
			ctx.value.putImageData(originalImageData.value, 0, 0);
			imageIsLoaded.value = true;
		}
	};
</script>

<template>
	<div class="scratch-card">
		<div class="scratch-card__bottom-layer">
			<IconStickerPack x-mirror />
			<p class="scratch-card__title">{{ reward }}</p>
		</div>
		<canvas
			id="scratchCanvas"
			ref="canvas"
			class="scratch-card__top-layer"
			width="340"
			height="286"
			@mousemove="continueErasing"
			@touchmove="continueErasing"
			@mouseup="stopErasing"
			@touchend="stopErasing"
			@mouseleave="stopErasing"
			@touchcancel="stopErasing"
			@mousedown="startErasing"
			@touchstart="startErasing"
		></canvas>
		<ScratchCardIdle :is-idle="isIdle" />
	</div>
	<ScratchCardReward
		:is-opened="isOpened"
		:reward="reward"
		@close="handleClose"
	/>
</template>

<style scoped lang="scss">
	.scratch-card {
		position: relative;
		width: 340px;
		height: 286px;
		z-index: 1;
		&__top-layer {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			background: url("../assets/img/scratch-layer.svg") no-repeat;
		}
		&__bottom-layer {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 20px;
			background: #fff;
			z-index: 1;
			border-radius: 40px;
		}
		&::before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 30px;
			opacity: 0.4;
			background: #5e4021;
			filter: blur(30px);
			width: 260px;
			height: 90px;
			left: 50%;
			bottom: -8px;
			transform: translateX(-50%);
			z-index: -1;
		}
		&__title {
			color: var(--color-main-100, #561750);
			text-align: center;
			font-size: 16px;
			font-weight: 700;
			text-transform: uppercase;
		}
	}
</style>
