<template>
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			opacity="0.4"
			d="M29.6323 14.296C30.1913 14.296 30.6548 13.8325 30.6548 13.2735V11.9103C30.6548 5.89847 28.828 4.07175 22.8162 4.07175H14.296V7.13901C14.296 7.69794 13.8325 8.16144 13.2736 8.16144C12.7147 8.16144 12.2512 7.69794 12.2512 7.13901V4.07175H9.18391C3.17206 4.07175 1.34534 5.89847 1.34534 11.9103V12.5919C1.34534 13.1509 1.80883 13.6144 2.36776 13.6144C3.67646 13.6144 4.75341 14.6913 4.75341 16C4.75341 17.3087 3.67646 18.3857 2.36776 18.3857C1.80883 18.3857 1.34534 18.8492 1.34534 19.4081V20.0897C1.34534 26.1015 3.17206 27.9283 9.18391 27.9283H12.2512V24.861C12.2512 24.3021 12.7147 23.8386 13.2736 23.8386C13.8325 23.8386 14.296 24.3021 14.296 24.861V27.9283H22.8162C28.828 27.9283 30.6548 26.1015 30.6548 20.0897C30.6548 19.5308 30.1913 19.0673 29.6323 19.0673C28.3236 19.0673 27.2467 17.9903 27.2467 16.6816C27.2467 15.3729 28.3236 14.296 29.6323 14.296ZM14.296 18.9582C14.296 19.5171 13.8325 19.9806 13.2736 19.9806C12.7147 19.9806 12.2512 19.5171 12.2512 18.9582V13.0418C12.2512 12.4829 12.7147 12.0194 13.2736 12.0194C13.8325 12.0194 14.296 12.4829 14.296 13.0418V18.9582Z"
			fill="white"
		/>
	</svg>
</template>
