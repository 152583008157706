<template>
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			opacity="0.4"
			d="M27.2314 15.9982V24.4218C27.2314 27.5245 24.7183 30.0376 21.6156 30.0376H10.3842C7.28147 30.0376 4.76843 27.5245 4.76843 24.4218V15.9982C4.76843 15.2261 5.4002 14.5943 6.17237 14.5943H8.93811C9.71028 14.5943 10.342 15.2261 10.342 15.9982V20.4066C10.342 21.4455 10.9177 22.4002 11.8302 22.8915C12.2374 23.1162 12.6866 23.2285 13.1499 23.2285C13.6834 23.2285 14.2169 23.0741 14.6802 22.7652L16.0139 21.8948L17.2494 22.7231C18.1058 23.2987 19.2009 23.3689 20.1134 22.8775C21.04 22.3861 21.6156 21.4455 21.6156 20.3925V15.9982C21.6156 15.2261 22.2474 14.5943 23.0196 14.5943H25.8274C26.5996 14.5943 27.2314 15.2261 27.2314 15.9982Z"
			fill="white"
		/>
		<path
			opacity="0.4"
			d="M29.3373 8.97858V10.3825C29.3373 11.9268 28.5932 13.1904 26.5295 13.1904H5.47046C3.32245 13.1904 2.6626 11.9268 2.6626 10.3825V8.97858C2.6626 7.43425 3.32245 6.17072 5.47046 6.17072H26.5295C28.5932 6.17072 29.3373 7.43425 29.3373 8.97858Z"
			fill="white"
		/>
		<path
			d="M15.4946 6.17069H7.74487C7.26753 5.65124 7.28157 4.85099 7.78699 4.34558L9.78057 2.35199C10.3 1.83254 11.1564 1.83254 11.6759 2.35199L15.4946 6.17069Z"
			fill="white"
		/>
		<path
			d="M24.241 6.17069H16.4913L20.31 2.35199C20.8295 1.83254 21.6859 1.83254 22.2053 2.35199L24.1989 4.34558C24.7043 4.85099 24.7184 5.65124 24.241 6.17069Z"
			fill="white"
		/>
		<path
			d="M18.7658 14.5943C19.5379 14.5943 20.1697 15.2261 20.1697 15.9982V20.3925C20.1697 21.5157 18.9202 22.1896 17.9936 21.5578L16.7301 20.7154C16.2668 20.4066 15.6631 20.4066 15.1857 20.7154L13.866 21.5859C12.9394 22.2036 11.704 21.5297 11.704 20.4206V15.9982C11.704 15.2261 12.3357 14.5943 13.1079 14.5943H18.7658Z"
			fill="white"
		/>
	</svg>
</template>
