<script setup></script>

<template>
	<div class="navigation">
		<svg
			width="360"
			height="74"
			viewBox="0 0 360 74"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			class="navigation__background"
			preserveAspectRatio="none"
		>
			<path
				d="M360 74.0001L0 74L3.01195e-06 39.5473C4.17027e-06 26.2977 4.74943e-06 19.6729 5.06086 13.9872C10.1217 8.30155 16.1031 7.60367 28.0657 6.20791C53.4732 3.24347 99.6349 3.82733e-05 180 4.52991e-05C260.365 5.23248e-05 306.527 3.24349 331.934 6.20794C343.897 7.6037 349.878 8.30158 354.939 13.9872C360 19.6729 360 26.2977 360 39.5474L360 74.0001Z"
				fill="#5F2059"
			/>
		</svg>
		<div class="navigation__buttons">
			<slot></slot>
		</div>
	</div>
</template>

<style scoped lang="scss">
	.navigation {
		position: fixed;
		bottom: 0;
		left: 0;
		height: 74px;
		width: 100vw;
		z-index: 10;

		&__background {
			width: 100%;
			height: 74px;
			position: absolute;
			bottom: 0;
		}
		&__buttons {
			position: relative;
			z-index: 1;
			padding: 21px 32px;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
</style>
